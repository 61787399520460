.notification-details {
    > .__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;
        font-size: 1.5em;
        font-weight: bold;

        .notification-icon,
        .__thumbnail {
            margin-right: 8px;
        }

        .__thumbnail {
            width: 100px;
            img {
                width: 100%;
            }
        }
    }

    .__attachments {
        margin-top: 16px;
        .__item {
            margin-bottom: 16px;
            max-width: 100%;
            > img {
                max-width: 100%;
            }
        }
    }
}
