.harbour-map-edit-panel {
    display: flex;
    flex-direction: column;

    position: fixed;
    bottom: 18px;
    right: 16px;
    height: 500px;
    width: 250px;
    overflow-y: auto;

    background: $white;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

    .edit-panel-section {
        flex: 1;
        .edit-panel-section-title {
            padding: 4px 4px 4px 8px;
            margin: 0;

            border-top: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            font-size: 14px;
        }
        &:first-child .edit-panel-section-title {
            border-top: none;
        }

        .edit-panel-section-content {
            padding: 8px;
        }
    }

    .actions-section {
        .edit-panel-section-content {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .edit-panel-action-btn {
        border-radius: 0;
    }

    .close-btn {
        width: 100%;
        align-self: flex-end;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .edit-panel-view-mode {
        width: 100%;
        margin-top: -8px;
        margin-bottom: 8px;

        .ant-radio-button-wrapper {
            width: 50%;
            text-align: center;
        }
    }
}