.external-link-configuration {
    max-width: 700px;
    margin: auto;
    > .__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-btn {
            width: 200px;
            margin-bottom: 8px;
        }
    }
    .__link-form {
        display: flex;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #eeeeee;
        .__preview {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 200px;
            .__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                width: 70px;
                height: 70px;
                border-radius: 60px;
                border: 1px solid transparent;
                img {
                    max-width: 60px;
                    max-height: 60px;
                }

                &.--noBackground {
                    background-color: transparent !important;
                }
            }
            .__name {
                text-align: center;
                font-weight: bold;
            }
        }
        .__fields {
            flex: 1;
            margin-left: 16px;
            .__field {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .__label {
                    min-width: 60px;
                }
                .ant-input {
                    width: 100%;
                    min-width: 0;
                }
                .color-picker {
                    height: 32px;
                    width: 100%;
                    > div:first-child {
                        width: 100%;
                        border-radius: 4px;
                        > div {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
            .__row {
                display: flex;
                .__field {
                    flex: 1;
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .__actions {
            display: flex;
            justify-content: space-between;
            .ant-btn {
                min-width: 200px;
            }
        }
        .__sort {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            padding-top: 1px;
            
            .ant-btn {
                flex: 1;
                border-radius: 0;
                margin-top: -1px;
                &:hover {
                    z-index: 2;
                }
                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
}