.rate-season-form {
    .__row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .ant-form-item {
            margin: 0;
            &:hover {
                z-index: 9;
            }
        }
        .ant-select {
            min-width: 150px;
            .ant-select-selection {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        .ant-input-number {
            margin-right: -1px;
            width: 100px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .__label {
            margin: 0 8px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
