.table-column-filter-dropdown {
    background: $white;
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.table-column-filter-dropdown.table-column-list-filter {
    .ant-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .list-row {
      padding-left: 8px;
      overflow: hidden;
      white-space: nowrap;
    }
    > .ReactVirtualized__List {
      padding-top: 8px;
    }
  }