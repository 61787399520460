.chart.line-chart {
    
    position: relative;
    
    .tooltip {
        display: none;
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: white;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 0 0;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        opacity: 0.8;
        min-width: 100px;
        z-index: 100;

        &.show {
            display: inline-block;
        }

        .tooltip-title {
            border-bottom: 1px solid  #d9d9d9;
            padding-left: 10px;
            padding-right: 10px;
        }
        .tooltip-content p {
            margin-bottom: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}