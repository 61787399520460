@import 'styles/style.scss';

.create-communication {
    .__field {
        margin-bottom: 8px;
    }

    .__content-field {
        min-height: 100px;
    }

    .__row {
        display: flex;
        margin-bottom: 8px;

        .__col {
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .__attachment-upload {
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid $primary-color;
        border-radius: 8px;
        .ant-upload {
            width: 100%;
            height: 100px;
        }
        .ant-upload-list {
            margin-top: -35px;
        }
        .__trigger {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            color: $primary-color;
            cursor: pointer;
        }
    }

    .__prev-attachments {
        margin-left: 60%;
        margin-bottom: 8px;
        .__item {
            display: flex;
            padding: 4px 8px;
            border-bottom: 1px solid #d9d9d9;
            .__label {
                flex: 1;
            }
            &:hover {
                background-color: #f7f7f7;
            }
        }
    }

    .__attachment-thumbnail {
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 8px;
        border: 1px solid $primary-color;
        border-radius: 8px;
        .ant-upload {
            width: 100%;
            height: 80px;
        }
        .__trigger {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            color: $primary-color;
            cursor: pointer;
        }
    }

    .__url-field {
        display: flex;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;
        background: #c0c0c0;
        border-radius: 8px;
        color: white;

        .__label {
            white-space: nowrap;
            margin-right: 16px;
        }
    }

    .__actions {
        display: flex;
        .__submit-btn {
            flex: 1;
        }
    }
}
