.access-request-info {
    margin-bottom: 8px;
    .__row {
        display: flex;
    }
    .__label {
        font-weight: bold;
        margin-right: 4px;
        white-space: nowrap;
        &:after {
            content: ' :';
        }
    }
}

.pending-access-requests-table {
    td.__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
