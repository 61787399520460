.rolling-weeks-table {
    .day-clickable {
        cursor: pointer;
    }
    .day-selected {
        background-color: rgba($secondary-color, .5);
    }

    .no-working,
    tr:hover > td.no-working {
        background: rgba($black, 0.2);
    }
}
