@import 'styles/_settings.scss';

.editable-svg-path {
    .svg-path {
        stroke: $primary-color !important;
    }

    .__boudary-box {
        .__box {
            fill: none;
            stroke: rgba(0, 0, 0, 0.1);
            stroke-dasharray: 4 2;
        }
        .__handles {
            g {
                circle {
                    fill: white;
                    stroke: rgba(0, 0, 0, 0.1);
                }
                text {
                    //font-size: 12px;
                    text-anchor: middle;
                    stroke: none;
                    fill: #555555;
                }
                &:hover,
                &:active {
                    circle {
                        fill: #cccccc;
                    }
                }
                &:hover {
                    cursor: grab;
                }
                &:active {
                    cursor: grabbing;
                }
            }
        }
    }

    .__editable-path-section {
        stroke: transparent;
        fill: none;
        &:hover {
            stroke: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }

    .__path-handles {
        .__point-handle {
            stroke: none;
            &:hover {
                cursor: grab;
            }
            &:active {
                cursor: grabbing;
            }
        }
        line {
            stroke: #787878;
        }
    }
}
