.cameras {
    .panel-content {
        display: flex;
        flex-wrap: wrap;
    }
    .camera {
        margin: 0 32px 32px 0;
        position: relative;
        background: $light-gray;

        .camera-title {
            padding: 2px 8px;
            background: #145dad;
            color: $white;
        }
    }
}
