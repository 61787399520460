// Layout

// Variables
$layout-header-background: $white;
$layout-header-height: 64px;

$layout-header-border: $light-gray;
$layout-content-background: none;
$layout-footer-background: $white;
$layout-footer-border: $light-gray;

.app-container > .ant-layout {
    > .ant-layout-sider {
        /*
        overflow: auto;
        height: 100vh;
        */
        position: fixed;
        left: 0;
        z-index: 101;

        .logo {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            height: $layout-header-height;
            line-height: $layout-header-height;

            background: $layout-header-background;

            .logo-text {
                transition: width .2s;
                width: 110px;
            }
        }
        .harbour-logo {
            text-align: center;
            margin-top: 16px;
            margin-bottom: 8px;
            img {
                object-fit: cover;
                border-radius: 100%;
                height: 150px;
                width: 150px;
                transition: .2s all;
                background: $white;
            }
        }
        .harbour-name {
            color: rgba(255, 255, 255, 0.65);
            text-align: center;
            margin-bottom: 16px;
        }
        .ant-menu {
            margin-bottom: 50px;
        }

        .ant-badge {
            right: 8px;
            position: absolute;
            top: 10px;
            .ant-badge-count {
                background: $primary-color;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            &.danger-badge {
                .ant-badge-count {
                    background: $danger-color;
                }
            }
        }

        .scrollarea {
            height: 100vh;
        }

        &.ant-layout-sider-collapsed {
            .harbour-name {
                display: none;
            }
            .harbour-logo img {
                height: 50px;
                width: 50px;
            }

            .logo-text {
                width: 0;
                visibility: hidden;
            }
        }
    }
    > .ant-layout > .ant-layout-header {
        padding-left: 215px;

        background: $layout-header-background;
        height: $layout-header-height;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
        display: flex;
        z-index: 100;

        position: fixed;
        width: 100%;

        .welcome-message {
            width: 100%;
            text-align: right;
            padding-right: 32px;
            white-space: nowrap;
        }
        .header-home-link {
            display: none;
        }
        .profile-btn {
            cursor: pointer;
            margin-top: 10px;
            background: $secondary-color;
            min-width: 40px;
            min-height: 40px;
        }
    }

    > .ant-layout > .ant-layout-content {
        margin-top: 64px;
        margin-left: 200px;
        padding: 16px;
    }

    > .ant-layout > div {
        transition: 0.2s padding, 0.2s margin;
    }

    &.sidebar-collapsed {
        > .ant-layout > .ant-layout-header {
            padding-left: 95px;
        }
        > .ant-layout > .ant-layout-content {
            margin-left: 80px;
        }
    }
}

.sider-toggle-menu-btn {
    position: absolute;
    top: 4px;
    left: 16px;

    .anticon {
        font-size: 24px;
    }

    &:hover {
        cursor: pointer;
    }
    &:active,
    &:focus {
        background: #fdfdfd;
        border-radius: 50%;
    }
}

.sider-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .65);
    z-index: 99;
}

@media only screen and (max-width: 900px) {
    .app-container > .ant-layout {
        > .ant-layout > .ant-layout-header {
            padding-left: 50px;

            .welcome-message {
                display: none;
            }
            .header-home-link {
                display: block;
            }
            .profile-btn {
                position: absolute;
                right: 16px;
            }
        }
        > .ant-layout > .ant-layout-content {
            margin-left: 0;
        }

        &.sidebar-collapsed {
            > .ant-layout > .ant-layout-header {
                padding-left: 50px;
            }
            > .ant-layout > .ant-layout-content {
                margin-left: 0;
            }
        }

        .ant-layout-sider {
            top: 64px;
            .sider-home-link {
                display: none;
            }

            .ant-layout-sider-trigger {
                display: none;
            }
        }
        > .ant-layout-sider-collapsed {
            margin-left: -80px;
        }
    }
}

.layout-user-menu {
    .ant-popover-inner-content {
        display: flex;
        flex-direction: column;
        padding: 0;

        button {
            min-width: 200px;
            border-radius: 0;
            border-top: none;
            border-left: none;
            border-right: none;
        }
        button:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        button:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: none;
        }
    }
}

.layout-select-harbour {
    width: 300px;
    .ant-popover-inner-content {
        padding: 0;
    }
    .ant-popover-inner-content .ant-btn {
        width: 100%;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
    }
}
