body > .app-container {
    min-height: 100%;
    height: 100%;
}

@import '~antd/dist/antd.css';


@import 'mixins';

@import 'settings';

@import './antd/antd';

@import 'utils';
@import 'layout';
@import 'forms';

@import 'notificationBell';

@import './pages/login';
@import './pages/home';
@import './pages/boatList';
@import './pages/showActivityModal';
@import './pages/calendar';
@import './pages/configuration';
@import './pages/harbourSvgMap';
@import './pages/HarbourMap/HarbourMapEditPanel';
@import './pages/reporting';
@import './pages/security';
@import './pages/showIncidentModal';
@import './pages/communication';
@import './pages/Commitment/CurrentCommitmentList';

@import './components/slider';
@import './components/displayForm';
@import './components/loading';
@import './components/panel';
@import './components/company/companyForm';
@import './components/permission/roleForm';
@import './components/tableColumnFilter';
@import './components/charts';
@import './components/rollingWeeksTable';
@import './components/svgMap';
@import './components/incidentAttachmentsModal';
@import './components/charts/lineChart';
@import './components/IncidentCard';
@import './components/form-fields/SelectMultipleField';
@import './components/form-fields/fieldset';
