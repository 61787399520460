@import '../settings';

.svg-map text {
    //fill: #787878;
    font-size: 2px;
}

.svg-map {
    .in,
    .out,
    .absence {
        stroke: #787878;
        stroke-linejoin: round;
        stroke-width: 0.2px;
    }
    .in {
        fill: $secondary-color;
    }
    .out {
        fill: $primary-color;
    }
    .absence {
        fill: $success-color;
    }
    .stopover {
        fill: #223c80;
    }
    .error {
        fill: $danger-color;
    }
    .zone {
        fill: rgba($secondary-color, 0.1);
        stroke: rgba($secondary-color, 0.8);
        stroke-linejoin: round;
        stroke-width: 1px;
    }
    .zone-text {
        font-size: 10px;
    }
    .zone-rect {
        stroke-dasharray: 4 2;
        stroke: #787878;
        fill: rgba(#787878, 0.1);
        cursor: move;
    }
    .zone-rotation-handle {
        stroke: none;
        fill: $dark-blue;
        cursor: grab;
    }

    .incident {
        stroke: $danger-color;
        fill: $danger-color;
        .place-text {
            fill: $white;
        }
    }
    .zone.incident {
        fill: rgba($danger-color, 0.1);
        .zone-text {
            fill: $danger-color;
        }
    }

    .zone-text,
    .place-text {
        stroke: none;
    }
}

.svg-map .drawing-polygon {
    fill: none;
    stroke: $primary-color;
    stroke-width: 0.2px;
}

.svg-map .drawing-polygon-point {
    fill: $primary-color;
}

.harbour-map {
    margin: -16px -16px -22px -16px;
    position: relative;
    .harbour-map-actions {
        position: absolute;
        right: 12px;
        bottom: 12px;
        button {
            display: block;
            margin-bottom: 8px;
        }
    }
    .harbour-map-msg {
        position: absolute;
        top: 50px;
        left: calc(50% - 50px);
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 4px 8px;
        border-radius: 8px;
    }

    &.view-mobile {
        .zone-text {
            font-size: 21px;
        }
    }
}

.incident-indicator {
    cursor: pointer;

    stroke-width: 3px;
    fill: $secondary-color;
    stroke: $dark-blue;

    .incident-indicator-attachment {
        stroke: $primary-color;
        stroke-width: 1px;
        fill: $dark-blue;
    }

    &.pending {
        stroke: $danger-color;
    }
    &.critical.pending {
        //fill: $danger-color;
        animation: incident-critical-animation 0.8s infinite;
    }
}

@keyframes incident-critical-animation {
    50% {
        fill: $danger-color;
    }
}

.harbour-map-legends {
    .harbour-map-legend-item {
        display: inline-block;
        margin-right: 32px;
        width: 100%;
        border-bottom: 2px solid transparent;

        .legend-color {
            display: inline-block;
            height: 12px;
            width: 12px;
            margin-right: 8px;
            border-radius: 4px;
        }
        .legend-label {
            display: inline-block;
        }
    }

    .harbour-map-legend-item.in .legend-color {
        background: $secondary-color;
    }
    .harbour-map-legend-item.out .legend-color {
        background: $primary-color;
    }
    .harbour-map-legend-item.absence .legend-color {
        background: $success-color;
    }
    .harbour-map-legend-item.stopover .legend-color {
        background: #223c80;
    }
    .harbour-map-legend-item.error .legend-color {
        background: $danger-color;
    }

    .harbour-map-legend-item:hover {
        cursor: pointer;
    }
    .harbour-map-legend-item.selected {
        border-bottom-color: $primary-color;
    }
}

// Sidebar
$harbour-map-reporting-height: 150px;
.harbour-map-sidebar {
    position: absolute;

    padding: 16px;
    padding-top: $harbour-map-reporting-height - 10px;

    width: 200px;
    top: 0;
    bottom: 0;
    left: 0;

    background: $white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

    .ant-slider-track {
        background-color: $secondary-color;
    }
}

.harbour-map-filters {
    margin-bottom: 32px;

    .ant-checkbox-wrapper {
        color: inherit;
        user-select: none;
    }
}

// Reporting
.harbour-map-reporting {
    display: flex;

    position: absolute;
    top: 0;
    left: 0;

    height: $harbour-map-reporting-height;
    width: 250px;
    padding: 16px 0 0 8px;

    background: $white;
    border-bottom-right-radius: 100px;

    clip: rect(0, 250px, $harbour-map-reporting-height - 10px, 0);

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: $harbour-map-reporting-height;
        border-bottom-right-radius: 100px;

        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

        clip: rect(-10px 200px 200px 50px);
    }

    .loading-chart_full {
        fill: $primary-color;
    }
    .loading_chart_empty {
        fill: #c7c7c7;
    }
    .loading-chart_text {
        fill: $primary-color;
    }

    .harbour-map-reporting_key-numbers {
        margin-left: 8px;
        > div {
            margin-top: 16px;
        }

        strong {
            color: $primary-color;
        }

        .anticon-caret-down {
            color: $danger-color;
        }
        .anticon-caret-up {
            color: $success-color;
        }
    }
}

.harbour-map-positions {
    &__item {
        $self: &;

        position: relative;
        display: flex;
        align-items: center;
        height: 30px;
        width: calc(100% + 16px);
        margin-right: -16px;
        line-height: 24px;
        padding-left: 4px;
        border-left: 4px solid #eee;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;

        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        > .ant-btn {
            opacity: 0;
        }

        &__label {
            flex: 1;
            user-select: none;

            input {
                border: 1px solid #eeeeee;
                border-radius: 4px;
            }
        }

        &__actions {
            position: absolute;
            top: 0;
            bottom: 0;
            background: $white;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding: 4px;

            right: -56px;
            transform: scaleX(0);

            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            > .ant-btn {
                margin-left: 4px;
            }
        }

        &--private {
            border-left-color: lighten($primary-color, 10%);
        }

        &:hover {
            border-left-color: lighten($secondary-color, 10%);
            border-bottom-color: #eee;
            border-top-color: #eee;
            cursor: pointer;

            #{$self}__actions {
                transform: scaleX(1);
                right: -112px;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .harbour-map {
        margin: -32px -32px -22px -32px;
    }
}
