
.attachments-modal {
    width: 80% !important;
    .ant-modal-body {
        display: flex;
    }
    .attachment-picture {
        margin-bottom: 16px;
        > img {
            max-width: 100%;
            max-height: 360px;
        }
    }
}