.select-multiple-field {
    font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-variant: tabular-nums;
    box-sizing: border-box;

    margin: 0;
    padding: 0;

    list-style: none;
    position: relative;
    display: inline-block;

    padding: 4px 11px;
    width: 100%;
    min-height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0,0,0,.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s;

    cursor: pointer;

    &__choice {
        position: relative;
        float: left;
        max-width: 99%;
        margin: 2px 4px 2x 0;
        padding: 0 10px;
        overflow: hidden;
        color: rgba(0,0,0,0.65);
        background-color: #fafafa;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        cursor: default;
        transition: padding .3s cubic-bezier(.645, .045, .355, 1);

        .anticon  {
            cursor: pointer;
            font-size: 10px;
            margin-left: 6px;
        }
    }

    &__search {
        border:none;
        background: none;
        width: auto;
        width: .75em;
        max-width: 100%;
        outline: 0;
    }

    &__dropdown{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 4px;
        background: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        z-index: 9;
        &__row {
            height: 30px;
            padding: 0 16px;

            &:hover {
                background: #d8edff;
            }
            &.selected {
                font-weight: bold;
            }
        }
        &__empty-text {
            color: $dark-gray;
            text-align: center;
            width: 100%;
        }
    }

    .ReactVirtualized__Grid {
        width: 100% !important;
        .ReactVirtualized__Grid__innerScrollContainer {
            width: 100% !important;
            max-width: none !important;
        }
    }
}