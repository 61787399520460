.svg-map-container {
    position: relative;
    background: #d7fdff;

    .svg-map-zoom {
        position: absolute;
        top: 0;
        right: 15px;
        width: 20px;

        .svg-map-slider {
            height: 150px;
            width: 100%;
        }

        .svg-map-zoom-reset-btn {
            margin-top: 8px;
            margin-left: -6px;
            &:hover,
            &:active,
            &:focus {
                color: $secondary-color;
                border-color: $secondary-color;
            }
        }
    }
    
}

.svg-map {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}