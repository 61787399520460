.security-calendar {
    .legends {
        margin-top: 8px;
        > div {
            display: inline-flex;
            align-items: center;
            margin-right: 16px;
        }
        .incident-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $secondary-color;
            width: 30px;
            height: 30px;
            margin-right: 16px;
            margin-top: 4px;
            margin-bottom: 4px;
            margin-left: 4px;
        }
        img {
            width: 75%;
            height: 75%;
        }
    }

    .security-day {
        .incident-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $secondary-color;
            border: 1px solid transparent;
            width: 25px;
            height: 25px;
            margin-bottom: 4px;

            &:hover {
                cursor: pointer;
                border-color: $primary-color;
            }

            img {
                width: 70%;
                height: 70%;
            }
        }
        .incident-icon > img {
            width: 70%;
            height: 70%;
        }
    }

    .ant-table-thead > tr > th, 
    .ant-table-tbody > tr > td {
        word-break: normal;
    }
}

.pending-incident-table {
    .incident-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $secondary-color;
        width: 25px;
        height: 25px;
        margin-right: 4px;
    }
    .incident-icon > img {
        width: 70%;
        height: 70%;
    }
}