@import '../settings';

$notification-margin: 10px;
$notification-padding: 10px;

#communication-page {
    .forecast {
        width: 100%;
        padding: 20px;
        margin-bottom: 15px;
        border-radius: 5px;

        button,
        .upload * {
            width: 100%;
        }
        .upload__button {
            * {
                width: initial;
            }
            .anticon-loading {
                margin-left: -24px;
                transition: none;
            }
        }
        .ant-col-12 {
            position: relative;
            .weather-forecast-updated-date {
                position: absolute;
                top: -20px;
                right: 5px;
            }
        }
    }
    .image-container {
        margin-top: 10px;
        width: 100%;
        height: 300px;
        text-align: center;
        position: relative;

        .left-button-image,
        .right-button-image {
            width: initial;
            border-radius: 50%;
            position: absolute;
            top: 50%;
        }

        .left-button-image {
            left: 0;
        }

        .right-button-image {
            right: 0;
        }
        img {
            max-width: 300px;
            max-height: 300px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .notifications {
        padding-right: 0;
        .notifications__history {
            list-style: none;
            width: 100%;
            max-height: calc(100vh - 320px);
            padding: 0;
            padding-right: 16px;
            overflow: auto;
        }
    }
    .notification {
        width: 100%;
        background: #f0f0f0;
        padding: $notification-padding;
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        border-radius: 8px;

        .__icon {
            min-width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .__thumbnail {
            width: 120px;
            min-width: 120px;
            border-radius: 4px;
            margin-right: 8px;

            img {
                width: 100%;
            }
        }

        .__details-btn {
            background: none;
            border: none;
            box-shadow: none;
            padding: 0;
        }

        &:not(:last-child) {
            margin-bottom: $notification-margin;
        }

        .notification__content {
            display: inline-block;
        }
        .notification__title {
            font-size: 1.5em;
            font-weight: bold;
        }
        .notification__text {
            font-size: 1.2em;
            color: $medium-gray;
        }
        .notification__date {
            color: $medium-gray;
            position: absolute;
            right: $notification-padding;
            bottom: $notification-padding;
        }
        .notification__options {
            position: absolute;
            right: $notification-padding;
            top: $notification-padding;
            cursor: pointer;
        }
        .notification__options:hover {
            animation-duration: 0.5s;
            transform: scale(1.4);
        }
    }
    .new-notification {
        position: relative;
        margin-bottom: $notification-margin;

        .ant-form-item-control {
            line-height: inherit;
        }

        .new-notification__select-type {
            display: none;
        }
        .new-notification__input {
            height: initial;
            width: calc(80% - 10px);
            padding-bottom: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            font-size: 1.2em;
        }
        .new-notification__button {
            height: 100%;
            position: absolute;
            bottom: 0;
            right: $notification-padding;
            width: 20%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .new-notification__input {
            min-height: 100px;
            width: 100%;
            padding-bottom: 50px;
            font-size: 1.2em;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .new-notification__button {
            height: 32px;
            position: absolute;
            bottom: $notification-padding;
            right: $notification-padding;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .new-notification__select-type {
            position: absolute;
            bottom: $notification-padding;
            left: $notification-padding;
            display: block;
            max-width: 25%;
        }
    }
}

.notification__menu {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 4px;

    .ant-btn {
        margin-top: -1px;
        border-radius: 0;
        &:first-child {
            margin-top: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        &:hover {
            z-index: 9;
        }
    }
}
