.boat-type-icon {
    width: 40px;
    height: 40px;
}

.boat-list {
    .ant-table-empty {
        margin-bottom: 64px;
    }
}

.boat-list-year {
    margin-bottom: 16px;
    text-align: right;
}

.boat-list-by {
    margin-bottom: 16px;
    text-align: left;
    float: left;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #F39D26;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #F39D26;
    border-color: #F39D26;
    box-shadow: -1px 0 0 0 #F39D26;
}

.ant-radio-button-wrapper:hover {
    color: #F39D26;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #F39D26;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #696969;
    border-color: #696969;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #F39D26;
    border-color: #F39D26;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #F39D26;
    border-color :#F39D26;
}

.ant-popover .count-outings {
    > div {
        margin: 8px 0;
    }
    > div:first-child {
        margin-top: 0;
    }
    > div:last-child {
        margin-bottom: 0;
    }
    label {
        width: 90px;
        display: inline-block;
        margin-right: 8px;

        &::after {
            content: ':';
            float: right;
        }
    }
}
