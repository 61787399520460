.notification-thumbnail-upload {
    margin-bottom: 8px;
    > .__trigger {
        width: 200px;
        height: 200px;
        padding: 0;

        img {
            width: 200px;
            height: 200px;
            object-fit: contain;
        }
    }
}

.notification-thumbnail-upload-modal {
    .ant-upload {
        width: 100%;
    }
    .__trigger {
        margin-bottom: 8px;
        width: 100%;
    }
}
