.configuration {
    flex: 1;
    flex-direction: row;

    > div {
        flex: 1;
    }

    .incident-category {
        margin-top: 50px;
        h3 {
            font-weight: bold;
        }
    }

    .incident-icon {
        display: flex; 
        align-items: center; 
        justify-content: center;
        height: 40px;
        width: 40px;
        margin-right: 16px;
        background: $secondary-color;
        border-radius: 50%;

        img {
            height: 75%;
            width: 75%;
        }
    }

    .ant-select-selection {
        min-width: 150px;
    }

    .ant-tabs {
        overflow: visible;
    }
}

.places-configuration {
    display: flex;
    > fieldset {
        flex: 1;
        margin-right: 32px;

        &:first-child {
            flex: 2;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
