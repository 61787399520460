.incident-modal {
    width: 800px !important;

    .incident-status-btn {
        display: inline-block;
        width: 200px;
        margin-left: 16px;
    }

    .incident-confirmedBy {
        color: $secondary-color;
        width: 100%;
        .anticon {
            margin-right: 8px;
        }
    }

    .incident {
        &__acknowledge-info,
        &__close-info {
            width: 100%;
            margin-bottom: 8px;
            .anticon {
                margin-right: 8px;
            }
        }
        &__acknowledge-info {
            .anticon {
                color: $primary-color;
            }
        }
        &__close-info {
            .anticon {
                color: $success-color;
            }
        }
    }

    .incident-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $secondary-color;
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }
    .incident-icon > img {
        width: 70%;
        height: 70%;    }

    .display-form .panel {
        margin-top: 0;
    }

    .attachments {
        display: flex;
    }

    .attachment-picture {
        margin-bottom: 16px;
        > img {
            max-width: 100%;
            max-height: 360px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .incident-modal {
        width: calc(100% - 20px) !important;
    }
}