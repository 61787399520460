.incident-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -16px;
}

.incident-card {
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;
    margin-right: 16px;
    padding: 16px;

    background: $white;
    border-radius: 4px;
    border: 3px solid transparent;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    transition: transform .2s ease;

    width: calc(25% - 16px);
    
    .incident-card-header {
        display: flex;
        justify-content: space-between;

        .incident-card-title {
            margin-bottom: 4px;
            font-weight: bold;
            font-size: 18px;

            .incident-icon {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                margin-right: 8px;
                line-height: 23px;

                img {
                    width: 70%;
                    height: 70%;
                }
            }
        }
    }
    .incident-card-content {
        padding: 0 8px;
        flex: 1;
    }
    .incident-card-footer {
        padding-top: 8px;
    }

    .incident-card-actions .ant-btn {
        width: 100%;
    }

    .incident-card-zone {
        font-size: 16px;
        .incident-card-zone-color {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 4px;
            margin-bottom: -1px;
            background: $secondary-color;
            border-radius: 4px;
        }
    }

    .incident-author {
        margin-top: 4px;
    }
    .incident-date,
    .incident-author {
        color: #a3a3a3;
    }
    
    .incident-confirmedBy {
        color: $secondary-color;
        .anticon {
            margin-right: 8px;
        }
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.025);
    }
    &:active,
    &:focus {
        transition-duration: .1s;
        transform: scale(.975);
    }

    &.critical {
        background: #ffdcd9;
        border: 3px solid $danger-color;
        .incident-icon {
            background-color: $danger-color;
        }
    }
}

@media only screen and (max-width: 1700px) {
    .incident-card {
        width: calc(33.3333% - 16px);
    }
}

@media only screen and (max-width: 1300px) {
    .incident-card {
        width: calc(50% - 16px);
    }
}


@media only screen and (max-width: 900px) {
    .security-calendar {
        display: none;
    }
    .security-actions .ant-btn {
        float: none;
        width: 100%;
    }
    .incident-card {
        width: 100%;

        .incident-card-actions .ant-btn {
            margin-bottom: 8px;
        }

        .incident-author-phone {
            display: block;
        }
    }
}