.partner-form {
    .__address {
        .__city {
            display: flex;

            > div {
                flex: 1;
                white-space: nowrap;

                &:first-child {
                    max-width: 150px;
                    margin-right: 8px;
                }
            }
        }
    }
    .__website {
        display: flex;
        margin-bottom: 8px;
        .__fields {
            flex: 1;
            .ant-input {
                border-radius: 0;
                margin-top: -1px;

                &:first-child {
                    border-top-left-radius: 4px;
                }
                &:last-child {
                    border-bottom-left-radius: 4px;
                }
            }
        }
        .__actions {
            .ant-btn {
                height: calc(100% + 1px);
                margin-top: -1px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    .__save-btn {
        width: 100%;
        margin-top: 16px;
    }

    .__picture {
        display: flex;
        margin-bottom: 8px;
        img {
           max-width: 100%;
           max-height: 300px;
        }
        .__delete-picture-btn {
            margin:  8px 0 0 -40px;
        }
    }

    .__thumbnail {
        .ant-btn {
            height: 200px;
            width: 200px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}