fieldset.bold > legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    font-weight: bold;
}
