.harbour-comments {
    width: 100%;
    max-width: 500px;

    .label {
        margin-bottom: 8px;
    }

    .panel.__comment {
        margin-bottom: 8px;
        .__footer {
            text-align: right;
            color: #a3a3a3;
            font-size: 14px;
            font-style: italic;
        }
    }

    .field {
        width: 100% !important;
        .ant-input {
            width: 100%;
        }
    }
}
