
@function calculatePercentOfPage($number) {
    @return calc(#{$number + vh} - #{(64 / (100 / $number)) + px} - 5px);
}
@mixin sm {
    @media screen and (max-height: 950px) {
        @content
    }
}
$motorboat-color: #59c1c6;
$multihull-color: #002140;
//$sailboat-color: #001529;
$sailboat-color: lighten($multihull-color, 15%);
$day-color: $reporting-main-color;
$night-color: $reporting-comparison-color;
$selected-filter-color: #3f91e8; 


#reporting-page {
    height: calc(calculatePercentOfPage(100) - 32px);

    .filtersPart {
        height: 20px;
    }
    .fifth {
        height: calc(#{calculatePercentOfPage(25)} - 15px - #{(16 * 0.25) + px});
        margin-bottom: 10px;
    }
    .four-fifth {
        height: calc(#{calculatePercentOfPage(75)} - 15px - #{(16 * 0.75) + px});
    }
    .mainPart {
        height: calc(#{calculatePercentOfPage(50)} - 15px - #{(16 * 0.50) + px});
        margin-bottom: 10px;

        .half {
            height: calc(#{calculatePercentOfPage(25)} - 10px - #{(16 * 0.25) + px});
            margin-bottom: 10px;
        }

        .quarter {
            height: calc(#{calculatePercentOfPage(16.5)} - 5px - #{(16 * 0.165) + px});
            margin-bottom: 10px;
        }
        .three-quarter {
            height: calc(#{calculatePercentOfPage(33.5)} - 15px - #{(16 * 0.335) + px});
            margin-bottom: 10px;
        }
        .half:last-child, .quarter:last-child, .three-quarter:last-child {
            margin-bottom: 0;
        }
        
    }
    .mainPart:last-child {
        margin-bottom: 0;
    }
}
.reporting {
    
    @include sm {
        h1, h2 {
            font-size: 1.3em;
        }
        h3, h4, h5 {
            font-size: 1.2em;
        }
        li, p {
            font-size: 0.85em;
        }
    }
    
    .chart {
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    .chart>svg {
        position: absolute;
        top: 0;
        left: 0;
    }
    
    g.main > rect {
        fill: $reporting-main-color;
    }
    g.compared > rect {
        fill: $reporting-comparison-color;
    }
    
    &.layout-panel {
        border: none;
        height: inherit;
        padding-top: 0;
        padding-bottom: 0;

        &> .panel-content {
            height: calc(100% - 36px);
            position: relative;
        }
        &> .panel-title {
            margin-bottom: 5px;
            max-height: 31.5px;
        }
    }
    .outing-repartition>.xAxis:not(:nth-child(3n)) {
        display: none
    }
    .chart>svg {
        color: #d9d9d9;
        
        path.main.line {
            fill: none;
            stroke: $reporting-main-color;
        }

        path.main.area {
            fill: $reporting-main-color;
            stroke: none;
            opacity: 0.3;
        }
        
        path.compared.line {
            fill: none;
            stroke: $reporting-comparison-color;
        }

        path.line.dotted {
            stroke-dasharray: 2, 2; 
        }

        path.compared.area {
            fill: $reporting-comparison-color;
            stroke: none;
            opacity: 0.3;
        }
        text{
            color: rgba(30, 41, 66, 0.65);
        }
    }
    
    .reporting-main-text {
        color: $reporting-main-color;
    }
    .reporting-compared-text {
        color: $reporting-comparison-color;
    }
    
    .overlay {
        fill: none;
        pointer-events: all;
    }
    
    .focus circle.main {
        fill: #F1F3F3;
        stroke: $reporting-main-color;
        stroke-width: 1px;
    }
    .focus circle.compared {
        fill: #F1F3F3;
        stroke: $reporting-comparison-color;
        stroke-width: 1px;
    }
    .hover-line {
        stroke: $reporting-main-color;
        stroke-width: 2px;
        stroke-dasharray: 3,3;
    }
    
    text.compared, .compared-text {
        color: $reporting-comparison-color;
    }
    text.main, .main-text {
        color: $reporting-main-color;
    }
    .boat-type, .boat-category {
        text-align: center;
    }
    
    .boat-category {
        font-size: 1.2em;

        .category {
            cursor: pointer;
        }
        
        .category.selected {
            border-bottom: $selected-filter-color solid 2px;
            border-left: 5px transparent solid;
            border-right: 5px transparent solid;
            display: inline-block;
        }
    }
    .boat-type {

        .boat-icon {
            height: 50px;
            width: 50px;
            background-color: #002140;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            cursor: pointer;
            position: relative;
        }
        @include sm {
            
            .boat-icon {
                height: 35px;
                width: 35px;
            }
        }

        p {
            width: 50px;
            font-size: 1.2em;
            text-align: center;
            display: inline-block;
            margin-bottom: 0;
        }
        
        .boat-icon > img {
            background-image: linear-gradient(to right,#f59e00 ,#f59e00 95%, transparent);
            background-position: inherit;
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
        }

        &.selected span{
            padding-bottom: 5px;
            border-bottom: $selected-filter-color solid 2px;
            border-left: 5px transparent solid;
            border-right: 5px transparent solid;
        }
        &> span {
            max-width: 100%;
            display: inline-block;
        }
        .main-text::after {
            background-color: $sailboat-color;
            content: "";
            display: block;
            margin-left: 8px;
            margin-right: 8px;
            height: 10px;
        }
        &.multihull .main-text::after {
            background-color: $multihull-color;
            opacity: 0.5;
        }
        &.motorboat .main-text::after {
            background-color: $motorboat-color;
            opacity: 0.5;
        }
        &.sailboat .main-text::after {
            background-color: $sailboat-color;
            opacity: 0.5;
        }
    }
    
    ul.legend {
        list-style-type: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding-left: 0;
        margin-bottom: 10px;
    }
    ul.legend > li {
        display: inline-block;
        margin-right: 10px;
    }
    .legend-display {
        display: inline-block;
        height: 15px;
        width: 15px;
        margin-right: 5px;

        
        &.top10 {
            &.night {
                background-color: $night-color;
            }
            &.day {
                background-color: $day-color;
            }
            &.faded {
                opacity: 0.4;
            }
        }
        &.outing-frequency.main {
            background-color: $reporting-main-color;
        }
        &.outing-frequency.compared {
            background-color: $reporting-comparison-color;
        }
    }
    .legend.line-legend {
        list-style-type: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding-left: 0;
        margin-bottom: 5px;

        li {
            position: relative;
            padding-left: 35px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 30px;
                height: 0;
            }

            &.main::before {
                border-bottom: 1px solid $reporting-main-color;
            }
            &.compared::before {
                border-bottom: 1px solid $reporting-comparison-color;
            }
            &.main-mean::before {
                border-bottom: 1px dashed $reporting-main-color;
            }
            &.compared-mean::before {
                border-bottom: 1px dashed $reporting-comparison-color;
            }
        }
    }
    .legend.outing-frequency {
        top: -25px;
        max-width: 125px;
        right: 0;
        bottom: initial;
        margin-bottom: 0;
    }

    &.empty::after {
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(209, 209, 209, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    &.empty  .ant-alert {
        z-index: 100;
    }

    &.top10  {
        .legend {
            height: 15%;
            position: initial;
        }
        ol {
            height: 85%;
            list-style-type: decimal;
            padding-left: 20px;
            overflow-y: hidden;

            .day {
                background-color: $day-color;
            }
            .night {
                background-color: $night-color;
            }
            .boat {
                z-index:100;
                padding-left: 0;
                text-shadow: 0 0 7px white;
            }
            
            & > li {
                margin-bottom: 3px;
                position: relative;
            }
            & > li > div {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            
            & > li > div> .bar > span{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: inline-block;
                height: 18%;
                z-index:1;
            }
        }
    }
    
    .occupancy-rate-empty {
        fill: #C7C7C7;
    }
    .occupancy-rate-full {
        fill: $reporting-main-color;
    }
    text.occupancy-rate, tspan.occupancy-rate  {
        font-weight: 600;
        stroke: none;
        fill: $reporting-main-color;
        font-size: 2.1em;
    }
    .occupancy-rate-description {
        font-size: 1.3em;
    }
    .evolution-compared {
        fill: $reporting-comparison-color;
    }    
    
    //calendar
    &.calendar {
        position: relative;
        padding: 5px;

        .row {
            height: 100%;
        }

        .row>*{
            height: 100%;
        }

        .calendar-row {
            width: 100%;
        }

        #calendar { 
            height: 100%;
            width: 100%;
            margin-bottom: 5px;
            //border: 2px solid #d9d9d9;
            border-radius: 25px;
            border-collapse: separate;
    
            th:first-child {
                border-radius: 4px 0 0 0;
            }
    
            th:last-child {
                border-radius: 0 4px 0 0;
            }
    
            tr:last-child .calendar-cell:last-child{
                border-radius: 0 0 4px 0;
            }
    
            tr:last-child .calendar-cell:first-child{
                border-radius: 0 0 0 4px;
            }
            
            th {
                height: 30px;
                background-color: #f0f2f5;
                font-weight: 500;
            }
            @include sm {
            
                th {
                    height: 20px;
                }
            }

            .calendar-cell, th {
                border: 1px solid #d9d9d9;
                text-align: center;
                width: 14.25%;
            }
        }

        .calendar-cell {
            position: relative;
        }

        .calendar-cell .value {
            margin-bottom: 0;
            height: 66%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 15%;
            font-size: 1.8em;
        }
    }
    
    .byType {
        height: 33%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &> * {
            position: absolute;
            bottom: 0;
            width: 33.3%;
        }
        &> .type.motorboat {
            left: 0.15%;
            background-color: $motorboat-color;
            opacity: 0.5;
        }
        &> .type.sailboat {
            left: 33.5%;
            background-color: $sailboat-color;
            opacity: 0.5;
        }
        &> .type.multihull {
            left: 66.85%;
            background-color: $multihull-color;
            opacity: 0.5;
        }
    }
    .title {
        color: rgba(30, 41, 66, 0.65);
        margin: 8px 0;
    }
    .calendar-value {
        display: inline-block;
        margin-right: 5px;
        width: 20px;

        &.min {
            border: transparent solid 10px;
            border-top: 10px solid;
        }
        &.max {
            border: transparent solid 10px;
            border-bottom: 10px solid;
        }
        &.mean {
            height: 13px;
            border-bottom: solid 5px;
            border-top: 5px solid;
        }
    }
    
    .calendar-legend{
        position: relative;
        
        ul {
            list-style: none;
            padding-left: 0;
        }

        h4 {
            margin-top: 2px;//To align with table border-collapse: 2px 2px;
            margin-bottom: 2px;
            font-size: 0.9em;
            border: 1px solid $primary-color;
            background-color: white;
            padding-left: 3px;
            padding-right: 3px;
            padding-top: auto;
            padding-bottom: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 4px 4px 0 0;
            height: 24.5px;
            text-align: center;
        }
        
        h4.selected {
            background-color: lighten($primary-color, 25%);
        }
        .value {
            float: right;
        }
    }
    .calendar-legend-values {
        font-size: 1.8em;
        position: absolute;
        left: 15px;
        top: 40%;
    }
    
    .calendar-legend-values:first-child {
        top: 20%;
    }
    .calendar-legend-boat-types {
        position: absolute;
        left: 15px;
        bottom: 0;
    }
    .calendar-date {
        position: absolute;
        top: 0;
        left: 3px;
        color: #b0b0b0;
        font-size: 12px;
    }
    .value.compound {
        height: 100%;
        padding-top: 0;
    }
    .value.compound > span {
        display: inline-block;
        width: 100%;
        font-size: 0.8em;
        max-height: 40%;
        position: absolute;
        top: 7.5%;
        left: 0;
    }
    .value.compound > span:last-child {
        top: 46%;
    }
    
    .optimisation.chart {
        height: 50%;
    }
    
    
    &.optimisation {
        position: relative;
        padding-right: 0;
        padding-left: 0;
    }
    .optimisation.chart, &.optimisation .panel-title {
        padding-left: 16px;
        padding-right: 16px;
    }

    .optimisation.table  {
        border-collapse: separate;
        width: 100%;
        
        ul {
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0px;
        }

        li>.definition {
            font-size: 0.8em;
        }
        
        li>.value {
            float: right;
        }
        
        td {
            word-break: normal;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        
        tr {
            word-break: normal;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        td:first-child {
            width: 20%;
        }

        .ant-table {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        table {
            border-spacing: 2px 2px;
        }

        td, th {
            border-spacing: 2px 2px;
            border: 1px solid #d9d9d9;
            border-radius: 0 0 4px 4px;
        }

        th {
            height: 30px;
            background-color: #f0f2f5;
            font-weight: 500;
        }

        th:first-child {
            border-radius: 0 0 0 0;
        }

        th:last-child {
            border-radius: 0 0 0 0;
        }

        @include sm {
            
            th {
                height: 20px;
                padding-top: 1px;
                padding-bottom: 1px;
            }

            tr > td:first-child{
                font-size: 0.8em;
            }
            
        }

        tr:last-child td:last-child{
            border-radius: 0 0 4px 0;
        }

        tr:last-child td:first-child{
            border-radius: 0 0 0 4px;
        }
    }
}

.chart.reporting.occupancy {
    width: 100%;
    height: 100%;

    .loading-chart_full {
        fill: $primary-color;
    }
    .loading_chart_empty {
        fill: $night-color;
    }
    .loading-chart_text {
        fill: $primary-color;
    }

    .loading-chart_numbers {
        //float: right;
        margin-left: 8px;
        width: 35%;
        height: 100%;
        position: absolute;
        display: inline-block;
    
        > div {
            position: absolute;
        }

        > div:first-child {
            top: 0;
        }
        
        > div:last-child {
            bottom: 0;
        }
        
        strong.main {
            color: $primary-color;
        }
        
        strong.empty {
            color: $night-color;
        }
        
        .anticon-caret-down {
            color: $danger-color;
        }
        .anticon-caret-up {
            color: $success-color;
        }
    }

    .chart {
        width: 65%;
        height: 100%;
        display: inline-block;
    }
}
.clickable {
    cursor: pointer;
}

.calendar-cell-popover-table td {
    text-align: right;

}
td.type:before {
    content: '';
    padding: 5px;
    margin-right: 2x;
}

td.type.sailboat:before {
    background-color: $sailboat-color;
    opacity: 0.5;
}
td.type.motorboat:before {
    background-color: $motorboat-color;
    opacity: 0.5;
}
td.type.multihull:before {
    background-color: $multihull-color;
    opacity: 0.5;
}
.calendar-cell-popover-table td:first-child {
    text-align: left;
}

.d3-tip {
    line-height: 1;
    padding: 3px;
    background: white;
    border: 1px solid #9C9C9C;
    color: #000;
    border-radius: 5px;
    z-index: 150;
    box-shadow: #9C9C9C 1px 1px;
    color: rgba(30, 41, 66, 0.65);
}