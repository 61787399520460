.current-commitment-list {
    display: flex;
    line-height: 26px;
    height: 32px;
    margin-top: 15px;
    border-radius: 4px;

    margin-left: 100px;

    &__item {
        display: flex;
        &__user-type {
            display: flex;
            padding: 0 8px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            color: #ffffff;

            .anticon {
                margin-left: 4px;
                line-height: 32px;
            }
        }
        &__user {
            padding: 0 8px;
            min-width: 150px;
            background: #f7f7f7;
            border: 1px solid #d9d9d9;
            border-left: none;
            white-space: nowrap;
        }
        &:first-child {
            .current-commitment-list__item{
                &__user-type {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;

                    background: $primary-color;
                    border-color: $primary-color;
                }
                &__user {
                }
            }
        }
        &:last-child {
            .current-commitment-list__item{
                &__user-type {
                    background: #145dad;
                    border-color: #145dad;
                }
                &__user {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
}

.current-commitment-list__item__menu {
    width: 200px;
    .ant-popover-inner-content {
        padding: 0;
    }

    button {
        min-width: 200px;
        width: 100%;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: none;
        }
    }
}

@media screen and (max-width: 1200px) {
    .current-commitment-list {
        margin-left: 16px;
    }
}

@media screen and (max-width: 650px) {
    .current-commitment-list {
        display: none;
    }
}