@import 'styles/_settings.scss';

.recipient-select {
    margin-bottom: 8px;

    .__trigger {
        position: relative;
        width: 100%;
        text-align: left;
        overflow: hidden;
        .__extra {
            display: block;
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 2px;
            background: $secondary-color;
            padding: 2px 8px;
            color: $white;
            border-radius: 4px;
            min-width: 30px;
            text-align: center;
        }
    }
}

.recipient-select-modal {
    width: 800px !important;
    .ant-modal-body {
        display: flex;
        padding: 0;

        > .__section {
            flex: 1;
            display: flex;
            flex-direction: column;

            border-right: 1px solid #e8e8e8;
            &:last-child {
                border-right: none;
            }

            .__item-list {
                flex: 1;
                border-bottom: 1px solid #e8e8e8;
                &:last-child {
                    border-bottom: none;
                }

                .__header {
                    .__title {
                        padding: 4px 16px;
                        margin: 0;
                    }
                    .__search {
                        .ant-input {
                            border-right: none;
                            border-left: none;
                            border-radius: 0;
                            padding-left: 16px;
                        }
                    }
                }

                .__content {
                    padding: 4px 0 8px 0;
                    overflow: auto;

                    .__item .__header {
                        display: flex;
                        height: 32px;
                        line-height: 32px;
                        padding-left: 16px;
                        > div {
                            flex: 1;
                            white-space: nowrap;
                        }
                        .ant-btn {
                            border-radius: 0;
                            display: none;
                        }

                        &:hover {
                            background: #f0f0f0;
                            .ant-btn {
                                display: block;
                            }
                        }
                    }

                    .__no-data {
                        text-align: center;
                        color: #aaaaaa;
                        min-height: 100px;
                    }
                }
            }

            &.__selected-section {
                .__item-list {
                    .__content {
                        max-height: 455px;
                        overflow: auto;
                        .__item {
                            .ant-btn {
                                background: $danger-color;
                                border-color: $danger-color;
                                color: $white;
                            }

                            .__user {
                                padding-left: 32px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
